import { Injectable, LOCALE_ID, inject } from '@angular/core'
import { Router } from '@angular/router';
import { createEffect, Actions, ofType, concatLatestFrom } from '@ngrx/effects'
import { catchError, of, exhaustMap, map, tap, switchMap, withLatestFrom } from 'rxjs'
import { AuthIRepository } from '../../data/auth.repository.api'
import * as authActions from './auth.actions'
import * as fromAuth from './auth.selectors'
import * as petsActions from '../pets/pets.actions'
import { AUTH_CONFIG_TOKEN } from '../../auth.config';
import { DefaultSessionService } from '../../services/session.service';
import { SHARED_SESSION_KEYS } from 'yummypets-js-core';
import { Store } from '@ngrx/store';
import { EXPLORER_ALLOWED_LANGS } from '../../domain/constant';

@Injectable()
export class AuthEffects {

  /***
   * - RULES
   * - Do no use multiple actions in effects
   * https://github.com/timdeschryver/eslint-plugin-ngrx/blob/main/docs/rules/no-multiple-actions-in-effects.md
   */

  private store = inject(Store)
  private readonly router = inject(Router)
  private readonly actions$ = inject(Actions)
  private readonly locale = inject(LOCALE_ID)
  private readonly authService = inject(AuthIRepository)
  private readonly sessionService = inject(DefaultSessionService)
  private readonly config = inject(AUTH_CONFIG_TOKEN)

  /**
   * - LOGIN EFFECTS
   */
  login$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.login),
    exhaustMap(action => 
      this.authService.login(action.credential).pipe(
        map((profile:any) => authActions.loginSuccess({ profile })),
        catchError((error) => of(authActions.loginFail({ error: error.message })))
    ))  
  ));
  
  loginSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.loginSuccess),
    tap((action) => { 

      // - Set Session  
      // this.sessionService.set(SHARED_SESSION_KEYS.user, action.profile)
      
      // - Redirect to custom i
      // this.router.navigate([this.config.redirectLoginUrl])

    })   
  ),  { dispatch: false });


  loadUserOnLoginSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.loginSuccess),
    map((payload: any) => authActions.load({id: payload.profile.id}))
  ));

  /**
   * - SIGNUP EFFECTS
   */
  signup$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.signup),
    exhaustMap(action => {
      return this.authService.signup(action.data).pipe(
        switchMap(profile => this.authService.getConsumerData(profile.id).pipe(map(cs => [profile, cs]))),
        map(datas => {
          /**
           * MERGE PROFILE WITH CS DATA CREATE METHOD
           */
          const [profile, cs] = datas
          profile.csData = cs

          return authActions.signupSuccess({ profile })
        }),
        catchError((error) => {
          return of(authActions.signupFail({ error: error.message }))
      })
    )}) 
  ));

  signupSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.signupSuccess),
    tap((payload) => {
      this.sessionService.set(SHARED_SESSION_KEYS.user, payload.profile)
    })),
    { dispatch: false }
  );


  /**
   * - LOAD USER EFFECTS
   */
  load$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.load),
    exhaustMap(payload =>
      this.authService.getCurrentUser(payload.id, true).pipe(  
        map(profile => {
          
          // - TEMPORARY CODE
          let csLang = profile.csData?.collection.lang.value
          const isAllowedCsLang = EXPLORER_ALLOWED_LANGS.includes(csLang)

          /**
           * - Force cs.lang for Yummypets' users
           */
          if(!csLang) {
          
            csLang = (profile.lang === 'en' || profile.lang === 'fr') ? profile.lang : 'en'
            // - TODO: Create factory
            profile.csData.collection.lang = { 
              id: 20,
              name: "lang",
              value_type: "text",
              consumer_type_id: 18,
              unique: true,
              value: csLang
            }
          }

          /**
           * - Redirect if locale does not match user lang
           * - Only on production environment
           */
          if(this.config.environment === 'staging') {
            if(csLang !== this.locale && isAllowedCsLang) {

              const langToRedirect = (EXPLORER_ALLOWED_LANGS.includes(csLang))? csLang : 'en' // add to domain core
              window.location.href = `/${langToRedirect}/dashboard`
            }
          }
                    
          
          return authActions.loadSuccess({ profile })
        }),
        catchError(error => {
          return of(authActions.loadFail({ error: error.message }))
        })
      )
    )
  ));


  loadSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.loadSuccess),
    tap((payload) => { 

      // - Set Session 
      this.sessionService.set(SHARED_SESSION_KEYS.user, payload.profile)
    }),
  ), { dispatch: false });

  LoadPetsOnUserLoadSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.loadSuccess),
    map((payload: any) => {

      if(payload.profile.defaultPetId) {
        return petsActions.loadPet({id: payload.profile.defaultPetId})
      } else {
        return petsActions.loadNoPet() // Set loaded to true without pet
      }
    
    })
  ));

  /**
   * - SWITCH PET EFFECTS
   */
  switchPet$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.switchPet),
    concatLatestFrom(action => this.store.select(fromAuth.profile)),
    exhaustMap(([payload, profile]) => {
      return this.authService.switchPet(profile!.id, payload.petId).pipe(  
        map(pet => authActions.switchPetSuccess({ pet })),
        catchError(error => of(authActions.switchPetFail({ error })))
      )
    })
  ));

  /**
   * - Reload Current Pet with consumer Data
   */
  reloadPetOnSwitchPetSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.switchPetSuccess),
    map((payload) => petsActions.loadPet({id: payload.pet.id})))
  );

  /**
   * - Update user defaultPetId
   */
  updateUserOnSwitchPetSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.switchPetSuccess),
    map((payload) => authActions.editDefaultPetid({id: payload.pet.id}))
  ), { dispatch: false });

  /**
   * - EDIT EFFECTS
   */
  editUser$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.edit),
    concatLatestFrom(() => this.store.select(fromAuth.profile)),
    exhaustMap(([payload, profile]) => {
      return this.authService.edit(profile!.id, payload.data).pipe(  
        map(user => authActions.editSuccess({ user })),
        catchError(error => of(authActions.editFail({ error })))
      )})
  ));

  /**
   * - EDIT RELOAD USER
   */
  reloadUserOnEditUserSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.editSuccess),
    map((payload) => authActions.load({id: payload.user.id})))
  );
  
}
