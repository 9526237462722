import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpInterceptorFn, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable, LOCALE_ID, inject } from "@angular/core";
import { Observable, tap } from "rxjs";
import { AUTH_CONFIG_TOKEN } from "../auth.config";
import { JSONObject } from "yummypets-js-core";
import { DefaultSessionService } from "../services/session.service";


// export const defaultTokenInterceptor: HttpInterceptorFn = (request, next) => {

//     console.log('call defaultTokenInterceptor')
    
//     const config = inject(AUTH_CONFIG_TOKEN)
//     // const sessionService = inject(DefaultSessionService)

//     const contentType = 'application/x-www-form-urlencoded; charset=UTF-8';
//     // const token = sessionService.getToken() || ''
//     // const lang = sessionService.getLang() || { iso:'en' }
//     const token = ''
//     const lang  = { iso:'en' }
//     const headerJson = <JSONObject>{}
//     console.log('call defaultTokenInterceptor3')


//     /**
//      *  - Override Content-Type
//      *  - Add Accept-Language
//      */
//     headerJson['Content-Type'] = contentType
//     headerJson['Accept-Language'] = lang.iso

//     /**
//      * - Content-type generated with boundary if body === formData instance
//      */
//     if(request.body instanceof FormData) {
//       delete headerJson['Content-Type']
//     }

//     /**
//      * - Adding Token for all url matching yummypets api domain except facebook/connect
//      */
//     if(request.url.includes(config.api) && !request.url.includes('/facebook/connect'))
//       headerJson['Authorization'] = `Bearer ${token}`
//       console.log('call defaultTokenInterceptor3')
//     /**
//      * - Clone initial Request setted up with new header
//      */
//     const headers = new HttpHeaders(headerJson);
//     const newRequest = request.clone({headers});


//     return next(newRequest).pipe(tap(event => {

          

//           if (event instanceof HttpResponse) {
//             console.log('set TOKEN')
//             console.log('set TOKEN')

//             if(event.body && event.body.authToken) {
//               this.sessionService.setToken((event.body as any).authToken)
//             }
    
//             if(event.body && event.body as any.extras && event.body as any.extras.authToken) {
//               sessionService.setToken(event.body.extras.authToken)
//             }
//           }
       
//         })
//     );
// }


@Injectable({
  providedIn: 'root',
})
export class DefaultTokenInterceptor implements HttpInterceptor {

    constructor() {
        console.log('DefaultTokenInterceptor')
    }

  private readonly config = inject(AUTH_CONFIG_TOKEN)
  private session = inject(DefaultSessionService)
  private locale = inject(LOCALE_ID)

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    const contentType = 'application/x-www-form-urlencoded; charset=UTF-8';
    const token = this.session.getToken()
    //const lang = this.session.getUser().lang || this.session.getLang() || { iso:'en' }
    const headerJson = <JSONObject>{}


    /**
     *  - Override Content-Type
     *  - Add Accept-Language
     */
    headerJson['Content-Type'] = contentType
    headerJson['Accept-Language'] = this.locale

    /**
     * - Content-type generated with boundary if body === formData instance
     */
    if(request.body instanceof FormData) {
      delete headerJson['Content-Type']
    }

    /**
     * - Adding Token for all url matching yummypets api domain except facebook/connect
     */
    if(request.url.includes(this.config.api) && !request.url.includes('/connect') && token)
      headerJson['Authorization'] = `Bearer ${token}`

    /**
     * - Clone initial Request setted up with new header
     */
    const headers = new HttpHeaders(headerJson);
    const newRequest = request.clone({headers});

    return next.handle(newRequest).pipe(tap(event => {

      if (event instanceof HttpResponse) {

        if(event.body && event.body.authToken) {
          this.session.setToken(event.body.authToken)
        }

        if(event.body && event.body.extras && event.body.extras.authToken) {
          this.session.setToken(event.body.extras.authToken)
        }
      }

    }))
  }
}
