<footer class="footer">
    <div class="footer__grid wrapper">
        <div class="footer__logo">
            <img [src]="logoSVG" alt="Explorer Logo">
        </div>

        <div class="footer__lang">
            @if(languageService.country()) {
            <mat-form-field appearance="outline" >
               
                    <mat-select panelClass="footer__select" [(ngModel)]="languageService.country()!.code" name="lang">
                        @for(country of countries; track country.id) {
                            <mat-option [value]="country.code" (click)="switchCountry(country.id)" >
                                {country.iso, select, gb {United Kingdom (English)} us {United States (English)} ca {Canada (English)} fr {France (Français)} be {Belgique (Français)} pt-br {Brasil (Português)} es-mx {México (Español)}}
                            </mat-option>
                        }
                    </mat-select>
            </mat-form-field>
            }
        </div>

        <hr class="footer__line">

        <div class="footer__links d-flex">
            <ul>
                <li><a routerLink="/legal" i18n="@@global.link.term-of-use">Term of use</a></li>
                <li><a routerLink="/privacy" i18n="@@global.link.privacy">Privacy policy</a></li>
                <li><a routerLink="/cookies" i18n="@@global.link.cookie">Cookie policy</a></li>
            </ul>
            <ul>
                <li><a href="mailto:explorer@yummypets.com" i18n="@@footer.link.contact">Contact us</a></li>
                <li><a href="https://www.facebook.com/yummypets/" target="_blank" i18n="@@footer.link.facebook">Facebook</a></li>
                <li><a href="https://fr.linkedin.com/company/yummypets" target="_blank" i18n="@@footer.link.linkdin">Linkedin</a></li>
            </ul>
        </div>
        
        <div class="footer__copyright">
            <a href="https://www.yummypets.com" target="_blank"><i class=""></i> Yummypets © 2011-{{ currentYear }}</a>
        </div>  
    </div>
</footer>