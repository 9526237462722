import { InjectionToken } from "@angular/core";

const initialSessionsKeys = {
    user: '_user',
    pet: '_pet',
    token: '_token',
    lang: '_lang',
    origin: '_origin'
}

export type AuthConfig = {
    environment: string,
    api: string,
    logLevel: string,
    redirectLoginUrl: string,
    redirectLogoutUrl: string,
    redirectUserGuardUrl: string,
    redirectPetGuardUrl: string,
    SESSSION_KEYS: any
  }

export const AUTH_CONFIG_TOKEN = new InjectionToken<AuthConfig>('AUTH_CONFIG_TOKEN');


/** Token to inject the bridge service */
export const STORAGE_REPOSITORY_TOKEN:
    InjectionToken<StorageRepository> =
        new InjectionToken<StorageRepository>('[AUTH] StorageRepository');

export interface StorageRepository {
  hello(): void;
}