import { ChangeDetectionStrategy, Component, ElementRef, OnInit, Renderer2, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from 'src/app/shared/button/button.component';
import { CDN } from 'src/app/core/constants';
import { ScrollAnimationDirective } from 'src/app/core/directives/scroll-animation.directive';
import { SeoService } from 'src/app/core/services/seo.service';
import { Router, RouterModule } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { VoiceRepositoryApi } from 'src/app/data/api/voice-repository.api';

@Component({
  selector: 'app-landing',
  standalone: true,
  imports: [
    CommonModule, 
    ButtonComponent, 
    RouterModule,
    ScrollAnimationDirective],
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LandingComponent implements OnInit {

  readonly logoSVG = `${CDN.url}logo_explorer.svg`
  readonly logoData = `${CDN.url}illus_GDPR.jpg`
  header!: HTMLElement
  stats$: Observable<{num_points: number}> | undefined

  readonly seoService = inject(SeoService)
  renderer = inject(Renderer2)
  readonly #voiceRepository = inject(VoiceRepositoryApi)


  ngOnInit() {
    this.findHeaderLogo()
    this.hideHeader()
    this.setSeo()
    this.stats$ = this.#voiceRepository.getStats()
  }

  private findHeaderLogo() {
    this.header = <HTMLElement>document.querySelector('.navbar')
  }

  private hideHeader() {
    this.renderer.addClass(this.header, 'is-transparent')
  }

  private showHeader() {
    this.renderer.removeClass(this.header, 'is-transparent')
  }

  private setSeo() {
    this.seoService.setTitle($localize`:@@seo.landing.title:Explorer - The leading panel of dog and cat owners`)
    this.seoService.setDescription($localize`:@@seo.landing.desc:Join Explorer, the leading panel of dog and cat owners, and participate in our 100% animal-related surveys.`)
    this.seoService.setImage($localize`:@@seo.landing.image:https://explore.yummypets.com/en/share_explorer.jpg`)
  }

  ngOnDestroy() {
    this.showHeader()
  }
}
