import { createReducer, on, Action } from '@ngrx/store'
import * as AuthActions from './auth.actions'
import { User } from '../../domain/user.model'
import { isConsumerFullfilled } from '../../domain/user-consumer.utils'

export const AUTH_FEATURE_KEY = 'auth'

export interface AuthState {
  profile: User | null
  isLoggingIn: boolean
  isFbConnecting: boolean
  error?: any
  isLoading: boolean
  loaded: boolean
  isAuthenticated: boolean
  isValidated: boolean
  isCsDataFullfilled: boolean // consumer
  isCsDataExpired: boolean
  isEditing: boolean
  hasPet: boolean
}

export interface AuthPartialState {
  readonly [AUTH_FEATURE_KEY]: AuthState
}

export const initialAuthState: AuthState = {
  isLoggingIn: false,
  isFbConnecting: false,
  profile: null,
  error: null,
  loaded: false,
  isLoading: false,
  isAuthenticated: false,
  isValidated: false,
  isEditing: false,
  isCsDataFullfilled: false,
  isCsDataExpired: false,
  hasPet: false,
}

const reducer = createReducer(
  initialAuthState,

  /**
   * ---------------------------------------------------
   * - LOGIN
   * ---------------------------------------------------
   */
  
    on(AuthActions.login, (state) => ({
      ...state,
      isLoading: true,
      error: null
    })),

    on(AuthActions.loginSuccess, (state, { profile }) => ({
      ...state,
      isLoading: false,
      profile,
    })),

    on(AuthActions.loginFail, (state, { error }) => ({ 
      ...state, 
      isLoading: false,
      error
    })),

  /**
   * ---------------------------------------------------
   * - SIGNUP
   * ---------------------------------------------------
   */
    on(AuthActions.signup, (state) => ({
      ...state,
      isLoading: true,
      error: null
    })),

    on(AuthActions.signupSuccess, (state,  { profile }) => ({
      ...state,
      isLoading: false,
      error: null,
      loaded: true,
      isAuthenticated: true,
      isCsDataFullfilled: profile.csData ? isConsumerFullfilled(profile.csData.collection) : false,
      profile
    })),

    on(AuthActions.signupFail, (state,  {error} ) => ({
      ...state,
      isLoading: false,
      error
    })),

  /**
   * ---------------------------------------------------
   * - LOGOUT
   * ---------------------------------------------------
   */
    on(AuthActions.logout, () => ({
      ...initialAuthState
    })),

   /**
   * ---------------------------------------------------
   * - PROFILE
   * ---------------------------------------------------
   */
    on(AuthActions.load, (state) => ({
        ...state,
        isLoading: true,
        loaded: false,
        error: null
    })),

    on(AuthActions.loadSuccess, (state, { profile }) => ({
      ...state,
      isLoading: false,
      isAuthenticated: true,
      isCsDataFullfilled: profile.csData ? isConsumerFullfilled(profile.csData.collection) : false,
      isCsDataExpired: profile.csData ? profile.csData.isExpired : false,
      hasPet: !!profile.defaultPetId,
      error: null,
      loaded: true,
      profile
    })),

    on(AuthActions.loadNoUser, (state) => ({
      ...state,
      loaded: true
    })),

    on(AuthActions.loadFail, (state, { error }) => ({ 
      ...state, 
      isLoading: false,
      profile: null,
      isAuthenticated: false,
      error
    })),

    on(AuthActions.updateCsData, (state, { isCsData }) => ({
      ...state,
      isCsDataFullfilled: isCsData
    })),

    on(AuthActions.editDefaultPetid, (state, { id }) => {
      const _state = { ...state };
      _state.profile!.defaultPetId = id;
      return _state;
    }),

    on(AuthActions.edit, (state) => ({
      ...state,
      isLoading: true,
    })),

    on(AuthActions.editSuccess, (state, { user }) => ({
      ...state,
      isLoading: false,
      error: null,
    })),

    on(AuthActions.editFail, (state, { error }) => ({
      ...state,
      isLoading: false,
      error
    })),

  /**
   * ---------------------------------------------------
   * - OTHERS
   * ---------------------------------------------------
   */
  on(AuthActions.resetError, (state) => ({
    ...state,
    error: null
  })),

)

export function authReducer(state: AuthState | undefined, action: Action) {
  return reducer(state, action)
}
