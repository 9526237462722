import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { fetchAsHttpParams } from "auth/src/lib/helpers/fetch-as-http-params";
import * as moment from "moment";
import { Observable, map, tap } from "rxjs";
import { PollRepository } from "src/app/domain/repositories/poll.repository";
import { SurveyRepository } from "src/app/domain/repositories/survey.repository";
import { environment } from "src/environments/environment";
import { Poll, PollMapper } from "yummypets-js-core";

@Injectable({ providedIn: 'root' })
export class PollRepositoryApi implements PollRepository {

    private path = `polls`
    readonly #http = inject(HttpClient);

    getUrl() {
        return `${environment.api}${this.path}`
    }

    fetchToday(): Observable<Poll | null>{
        const today = moment(new Date()).format("DD/MM/YYYY")
        const options = { params: new HttpParams().set('date', today) }
        return this.#http.get(`${this.getUrl()}`, options).pipe(
          map((r:any) => {
            const data  = r.collection[0]
            if(data)
              return new PollMapper().mapFromEntity(Object.assign({}, data.resource,  data._session)) 
            else
              return null
            //console.log('new Mapper', new PollMapper().mapFromEntity(Object.assign({},  data.resource,  data._session)) )
           
        }))
    }
    

    fetchAnswers(pollId: number): Observable<any> {
      return this.#http.get(`${this.getUrl()}/${pollId}/answers`).pipe(
        map((data:any) => data.collection.map((item:any) => item.resource ))) // mapper Answer
    }
    
    answer(pollId: number, answerId: number): Observable<any>  {
        // convert to entity
        const params = { answer_id: answerId }
        return this.#http.post(`${this.getUrl()}/${pollId}/answers`, fetchAsHttpParams(params))
    }
   
}