import { Component, LOCALE_ID, inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Subject } from 'rxjs'
import { BoardNavComponent } from '../board-nav/board-nav.component'
import { ACLService, AuthFacade, PetsFacade } from 'auth'
import { Router, RouterModule } from '@angular/router'
import { Actions } from '@ngrx/effects'
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@Component({
  selector: 'ex-navbar-auth',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatTooltipModule,
    BoardNavComponent,
    MatProgressSpinnerModule
  ],
  templateUrl: './navbar-auth.component.html',
  styleUrls: ['./navbar-auth.component.scss']
})
export class NavbarAuthComponent {

  #authFacade = inject(AuthFacade)
  #petsFacade = inject(PetsFacade)
  #ACLService = inject(ACLService)
  actions$ = inject(Actions)
  router = inject(Router)
  locale = inject(LOCALE_ID)

  tradEditUser = $localize`:@@board.menu.user-edit:Edit user`
  tradEditPet = $localize`:@@board.menu.pet-edit:Edit my pet`

  isAuthenticated = this.#authFacade.isAuthenticated
  isLoading = this.#authFacade.isLoading
  profile = this.#authFacade.profile
  currentPet = this.#petsFacade.currentPet
  currentRole = this.#ACLService.currentRole

  cmpntDestroyed$ = new Subject()
}
