import { Injectable, inject } from '@angular/core'
import { select, Store } from '@ngrx/store'
import * as AuthActions from './auth.actions'
import * as PetsActions from '../pets/pets.actions'
import * as AuthSelectors from './auth.selectors'
import { Credentials } from '../../domain/credentials'
import { SHARED_SESSION_KEYS } from 'yummypets-js-core'
import { DefaultSessionService } from '../../services/session.service'
import { Router } from '@angular/router'
import { AUTH_CONFIG_TOKEN } from '../../auth.config'

@Injectable({ providedIn: 'root'})
export class AuthFacade {

  private router = inject(Router)
  private readonly store = inject(Store)
  private readonly sessionService = inject(DefaultSessionService)
  private readonly config = inject(AUTH_CONFIG_TOKEN)

  /**
   * @description Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  readonly profile = this.store.selectSignal(AuthSelectors.profile)
  readonly isAuthenticated = this.store.selectSignal(AuthSelectors.isAuthenticated) // unsubscribe after first value
  readonly isValidated = this.store.selectSignal(AuthSelectors.isValidated)
  readonly isCsDataFullfilled = this.store.selectSignal(AuthSelectors.isUserCsDataFullfilled)
  readonly isCsDataExpired = this.store.selectSignal(AuthSelectors.isUserCsDataExpired)
  readonly isLoading = this.store.selectSignal(AuthSelectors.isLoading)
  readonly error = this.store.selectSignal(AuthSelectors.error)

  /**
   * @deprecated Use signals instead
   */
  profile$ = this.store.pipe(select(AuthSelectors.profile))
  isLoading$ = this.store.pipe(select(AuthSelectors.isLoading)) 
  isAuthenticated$ = this.store.pipe(select(AuthSelectors.isAuthenticated)) // unsubscribe after first value
  isValidated$ = this.store.pipe(select(AuthSelectors.isValidated))
  error$ = this.store.pipe(select(AuthSelectors.error))
  loaded$ = this.store.pipe(select(AuthSelectors.loaded))

  autoLogin() {
    this.store.dispatch(AuthActions.autoLogin())
  }

  loadNoUser() {
    this.store.dispatch(AuthActions.loadNoUser())
  }

  /**
   * 1. Call API without token
   * 2. Save token, user, pets, currentPet in session
   */
  login(credential: Credentials) {
    this.store.dispatch(AuthActions.login({ credential }))
  }

  reload(id: number) {  
    this.store.dispatch(AuthActions.load({ id }))
  }

  logout() {
    this.sessionService.remove(SHARED_SESSION_KEYS.user)
    this.sessionService.remove(SHARED_SESSION_KEYS.token)
    this.sessionService.remove(SHARED_SESSION_KEYS.pet)
    this.store.dispatch(AuthActions.logout())
    this.store.dispatch(PetsActions.purge())
    this.router.navigate([this.config.redirectLogoutUrl])
  }

  signup(data: any) {
    this.store.dispatch(AuthActions.signup({ data }))
  }

  edit(data: any) {
    this.store.dispatch(AuthActions.edit({ data }))
  }

  validate() {
    this.store.dispatch(AuthActions.validate())
  }

  delete() {
    this.store.dispatch(AuthActions.erase())
  }

  switchPet(id: number) {
    this.store.dispatch(AuthActions.switchPet({ petId: id }))
  }

  resetError() {
    this.store.dispatch(AuthActions.resetError())
  }

}
