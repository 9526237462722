<mat-dialog-content>
  <div class="dialog__wrapper dialog__wrapper--flex">
    <div class="dialog__header">
      <img src="https://yp-explorer.s3.amazonaws.com/v2/illus_broken-leash.svg" alt="" /> 
    </div>

    <div class="dialog__content">
      <p class="page__title mt10" i18n="@@dialog.block.title">Access denied</p>
      <p class="page__intro page__intro--grey" i18n="@@dialog.block.title">We have detected that you are living in a country where we are not allowing registrations. We are only <strong>accepting members</strong> from <strong>France, Belgium, Canada, the United States and the United Kingdom.</strong>
        We apologize for the inconvenience.</p>

      <div class="halign--right text--center">
        <button type="button"
                (click)="confirm()"
                class="btn btn--primary" i18n="global.btn.ok">Ok</button>
      </div>
    </div>
  </div>
</mat-dialog-content>
