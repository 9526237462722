import { ApplicationConfig, importProvidersFrom } from '@angular/core'
import {
  InMemoryScrollingFeature,
  InMemoryScrollingOptions,
  provideRouter,
  withInMemoryScrolling,
  withViewTransitions
} from '@angular/router'
import { provideAnimations } from '@angular/platform-browser/animations';

import { appRoutes } from './app.routes'
import { StoreModule } from '@ngrx/store'
import { EffectsModule, provideEffects } from '@ngrx/effects'
import { provideAuthModule } from 'auth'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { provideImgixLoader } from '@angular/common';
import { CLOUDFRONT_URLS } from './contants';
import { errorInterceptor } from './core/errors/error.interceptor';
import { environment } from '../environments/environment';

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
};

const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling(scrollConfig);

export const appConfig: ApplicationConfig = {
  providers: [
    provideImgixLoader(CLOUDFRONT_URLS.explorer_v2),
    provideHttpClient(
      withInterceptorsFromDi(),
      withInterceptors([errorInterceptor])
    ),
    provideImgixLoader('https://yp-explorer.s3.amazonaws.com/v2/'),
    importProvidersFrom(StoreModule.forRoot({}), EffectsModule.forRoot([])),
    importProvidersFrom(StoreDevtoolsModule.instrument()),
    //provideStoreDevtools({ maxAge: 25,  logOnly: true}), // logOnly: !isDevMode}), // Retains last 25 states logOnly: !isDevMode()
    provideRouter(
      appRoutes,
      withViewTransitions(),
      inMemoryScrollingFeature,
    ),  // withEnabledBlockingInitialNavigation()
    provideAnimations(),
    
    provideAuthModule({
      environment: environment.name,
      api: environment.api,
      logLevel: 'DEBUG',
      redirectLoginUrl: '/dashboard',
      redirectLogoutUrl: '/',
      redirectPetGuardUrl: '/',
      redirectUserGuardUrl: '/login',
      SESSSION_KEYS: {
        user: 'EXPLORE_USER',
        pet: 'EXPLORE_PET',
        token: 'EXPLORE_TOKEN',
        lang: 'EXPLORER_LANG',
        origin: 'EXPLORER_ORIGIN'
      }
    }),
  ]
}
