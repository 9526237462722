import { Injectable, Signal, computed, inject } from "@angular/core";
import { switchMap, pipe, Observable, of } from "rxjs";
import { AuthFacade } from "../+state/auth/auth.facade";
import { PetsFacade } from "../+state/pets/pets.facade";


export enum Roles {
    Guest = -1,
    User = 1,
    Validated = 2,
    Pet = 3,
    PetIncomplete = 4,
    UserWithPet = User | Pet,
    UserValidatedWithPet = Validated | Pet,
    UserCS = 6,
    PetCS = 7,
    UserCSExpired = 8,
    PetCSExpired = 9,
    FullCS = UserCS | PetCS
}

@Injectable({ providedIn: 'root' })
export class ACLService {

    #authFacade = inject(AuthFacade)
    #petsFacade = inject(PetsFacade)

    currentRole: Signal<Roles> = computed(() => {

        // - SPECIAL CASE - DATE BORN MISSING
        if (this.#authFacade.isCsDataFullfilled() && 
            this.#petsFacade.isCsDataFullfilled() &&  
            !this.#petsFacade.isDataFullfilled()) {
                
                return Roles.PetIncomplete
        }

        // - FULL CS DATA
        if (this.#authFacade.isCsDataFullfilled() && 
            this.#petsFacade.isCsDataFullfilled() &&
            !this.#authFacade.isCsDataExpired() &&
            !this.#petsFacade.isCsDataExpired()
        ) {

                return Roles.FullCS
        }

        // - MISSING CS DATA PET ONLY
        if (this.#authFacade.isCsDataFullfilled() && 
            this.#petsFacade.isCsDataFullfilled() &&
            this.#authFacade.isCsDataExpired()
        ) {

                return Roles.UserCSExpired
        }

        // - MISSING CS DATA PET ONLY
        if (this.#authFacade.isCsDataFullfilled() && 
            this.#petsFacade.isCsDataFullfilled() &&
            !this.#authFacade.isCsDataExpired() &&
            this.#petsFacade.isCsDataExpired()
        ) {

                return Roles.PetCSExpired
        }

        // - MISSING CS DATA PET ONLY
        if (this.#authFacade.isCsDataFullfilled() && 
            !this.#petsFacade.isCsDataFullfilled()) {

                return Roles.UserCS
        }
        
        // - MISSING FULL CS DATA
        if (this.#petsFacade.currentPet())
            return Roles.Pet
      
        // - MISSING PET
        if (this.#authFacade.isAuthenticated())
            return Roles.User
       
        return Roles.Guest
    });
    
}