import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CDN } from 'src/app/core/constants'
import { AuthFacade, AuthIRepository } from 'auth'
import { LanguageService } from 'src/app/core/services/language.service'
import { MatSelectModule } from '@angular/material/select'
import { MatFormFieldModule } from '@angular/material/form-field'
import { COUNTRIES, LANGUAGES, getLangIsoAllowedByYummypets } from 'src/app/domain/lang'
import { FormsModule } from '@angular/forms'
import { Router, RouterModule } from '@angular/router'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { DefaultSessionService } from 'auth/src/lib/services/session.service'
import { COUNTRY_COOKIE } from 'src/app/contants'

@Component({
  selector: 'ex-main-footer',
  standalone: true,
  imports: [
    CommonModule,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    RouterModule
  ],
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainFooterComponent implements OnInit {

  readonly #authFacade = inject(AuthFacade)
  readonly #authApi = inject(AuthIRepository)
  readonly #sessionService = inject(DefaultSessionService)
  languageService = inject(LanguageService)

  countries = COUNTRIES
  currentCountry: string | undefined
  currentYear: string | undefined
  destroyRef = inject(DestroyRef)
  readonly logoSVG = `${CDN.url}logo_explorer-footer.svg`

  ngOnInit(): void {
    this.currentYear = this.getCurrentYear()
    this.currentCountry = this.languageService.country()?.code
  }

  getCurrentYear(): string {
    return new Date().getFullYear().toString()
  }

  /**
   * - CONNECTED (must patch user lang) + REDIRECT
   * - NOT CONNECTED + COOKIE + REDIRECT
   */
  switchCountry(id: number) {

    /**
     * - Get cs.lang and lang by country
     */
    const idLanguage = COUNTRIES.find(e => e.id === id)!.languages[0]
    const csLangIso = LANGUAGES.find(e => e.id === idLanguage)!.code
    const langIso = getLangIsoAllowedByYummypets(csLangIso)   

    if(this.#authFacade.isAuthenticated()) {

      const userId = this.#authFacade.profile()!.id
      this.#authApi.editLangAndCountry(userId, langIso, csLangIso, id)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.#sessionService.set(COUNTRY_COOKIE, id)
          window.location.href = `/${csLangIso}/dashboard`
      })
    } else {
      this.#sessionService.set(COUNTRY_COOKIE, id)
      window.location.href = `/${csLangIso}`
    }
    
  }

}

