import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { AuthFacade } from "auth";
import { Observable, of, throwError } from "rxjs";
import { API_ERRORS } from "src/app/contants";
import { ApiStatus } from "yummypets-js-core";

 /**
 * The purpose of this service is to :
 * - Format errors from server
 * - provide key for translation
 */
@Injectable({ providedIn: 'root'})
export class HttpErrorService  { // Provide Repository

    readonly authFacade = inject(AuthFacade)

    /**
     * @description Public method to dispatch error
     */
    public handleError(error: HttpErrorResponse): Observable<Error> {

        let serverError

        switch(error.status) {

            case ApiStatus.NON_AUTHORIZED: // - 401
                serverError = this.handleUnauthorized(error)
                break;

            case ApiStatus.FORBIDDEN: // - 403
                serverError = this.handleForbidden(error);
                break;

            case ApiStatus.CONFLICT: // - 409
            case ApiStatus.NOT_FOUND: // - 404
            default:
                serverError = this.handleOtherErrors(error)
            }

        return serverError
    }

    private handleUnauthorized(error: HttpErrorResponse) {
        if(!error!.url!.includes('/connect')) {
            // this.authService.purge()
            // this.authFacade.purge()
            // this.petsFacade.purge()
        }
        return this.getErrorMessage(error)
    }

    /**
     * @description Handle forbidden error 
     * @param error 
     * @returns new Error with translation key
     */
    private handleForbidden(error: HttpErrorResponse): Observable<Error> {

        const err = this.retrieveErrorFromHttpResponse(error)

        switch(err) {
        // case forbidden
        case API_ERRORS.accountValidation:
            return throwError(() => new Error('errors.account_validation'))
        case API_ERRORS.accountBlocked:
            return throwError(() => new Error('errors.account_blocked'))
        default:
            return this.getErrorMessage(error)
        }
    }

    private handleOtherErrors(error: HttpErrorResponse) {
        return this.getErrorMessage(error)
    }

    /**
     * @param {Object} error http error
     * To extract error message sent from backend
     * and to send it as our own error
     */
    getErrorMessage(err: HttpErrorResponse): Observable<Error> {
        
        let error: any

        if (err && err instanceof HttpErrorResponse) {
    
          error = this.retrieveErrorFromHttpResponse(err)

            /**
             * UNKNOWN ERROR
             */
            if(typeof error === null) {

                return throwError(() => new Error('errors.error'))
            }
          
            /**
             * STRING FORMAT
             */
            if (typeof error === 'string') {

                error = `errors.${this.setKeyFormat(error)}`
            }

            /**
             * OBJECT FORMAT
             */
            if (typeof error === 'object') {
                for (const key in error) {
                    if (error[key]) {
                        error = `errors.${this.setKeyFormat(error[key])}` // /!\ return only the first one
                    }
                }
            }
    
           return throwError(() => new Error(error))
    
        }

        if(error.message) {
            return throwError(() =>  new Error(error.message))
        }

        //return of(new Error('errors.error'))
        return throwError(() => new Error('errors.error'))
    }

    /**
     * Handle different's nodes error from backend
     * { code: xxx, error: { key: message} }
     */
    private retrieveErrorFromHttpResponse(errorResp: HttpErrorResponse): string | Object | null {
        if(errorResp && errorResp.error)
          return errorResp.error.error || null
        return null
    }
    
    /**
     * Format string error to match our translation key
     */
    private setKeyFormat(error: string): string {
        return error.toLowerCase().replace(' ','_')
    }

}