<mat-dialog-content>
  <div class="dialog__wrapper dialog__wrapper--flex">
    <div class="dialog__header">
      <img src="https://yp-explorer.s3.amazonaws.com/v2/illus_broken-leash.svg" alt="" /> 
    </div>

    <div class="dialog__content">
      @if(data.title) {
        <p class="page__title mt-10">{{ data.title }}</p>
      }
      <p class="page__intro page__intro--grey">{{ data.desc }}</p>

      <div class="halign--right text--center">
        <button type="button"
                (click)="confirm(true)"
                class="btn btn--primary" i18n>Ok</button>
      </div>
    </div>
  </div>
</mat-dialog-content>
