import { HttpParameterCodec, HttpParams } from "@angular/common/http";
import * as moment from "moment";
import { Lang, ResponsiveBreakpoints, ResponsiveDevice } from "src/app/contants";

export function urlEncoreArray(httpParams:HttpParams, key:string, value:Array<any>){
    value.forEach(value => {
        httpParams = httpParams.append(key, value);
    });
    return httpParams
}

export class WebHttpUrlEncodingCodec implements HttpParameterCodec {
    encodeKey(k: string): string { return encodeURIComponent(k); }

    encodeValue(v: string): string { return encodeURIComponent(v); }

    decodeKey(k: string): string { return decodeURIComponent(k); }

    decodeValue(v: string) { return decodeURIComponent(v); }
}

export function fetchAsHttpParams(params: any): HttpParams {

    return Object.getOwnPropertyNames(params)
    .reduce((p, key) => {
        if(key.includes('[]')&& Array.isArray(params[key]) ) {
            return urlEncoreArray(p, key, params[key])
        }
        return p.set(key, params[key])
    }, new HttpParams({ encoder: new WebHttpUrlEncodingCodec() }));
    // https://github.com/angular/angular/issues/11058 => bug + convert as whitespace
}

export function isMobile(): boolean {
    return (window.innerWidth < ResponsiveBreakpoints.MOBILE)? true : false
  }
  
  export function isDesktop(): boolean {
    return (window.innerWidth > ResponsiveBreakpoints.TABLET)? true : false
  }
  
  export function getCurrentDevice(): ResponsiveDevice  {
    const size = window.innerWidth
  
    if(size < ResponsiveBreakpoints.MOBILE) {
      return  ResponsiveDevice.mobile
    } else if (
      size >= ResponsiveBreakpoints.MOBILE
      && size < ResponsiveBreakpoints.TABLET) {
        return ResponsiveDevice.tablet
    } else {
      return ResponsiveDevice.desktop
    }
  }

  export function getDateFormatByLocale() {
    return moment().creationData().locale.longDateFormat('L');
  }
  
  export function getDateFormatByLocaleForMask(locale:string) {
    switch(locale) {
      case Lang.FR:
      case Lang.ES_MX:
        return 'd0/M0/0000'
      case Lang.EN:
      case Lang.PT_BR:
      default:
        return 'M0/d0/0000'
    }
  }